/** @format */

import React, { lazy, Suspense, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import encryptStorage from "../utils/Encryptstorage";
import requests from "../utils/Requests";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
const TopNav = lazy(() => import("../pages/global-components/top_nav"));
const DynamicBanner = lazy(() =>
  import("../pages/global-components/dynamic_banner")
);
const renderLoader = () => <p>Loading</p>;
const CartDetails = () => {
  const history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [cartDetailList, setcartDetailList] = useState(null);
  const [postList, setpostList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [userDetails, setuserDetails] = useState();
  const [bookTotal, setbookTotal] = useState("");
  const [exitData, setexitData] = useState(null);
  const [isLoading, setisLoading] = useState(null);
  useEffect(() => {
    const preObj = encryptStorage.getItem("mofForBooking"); // obj

    if (preObj != undefined) {
      let existObj = JSON.parse(preObj);
      console.log("sicbsuibcsbvbdbvd", existObj);
      setexitData(existObj);
    }

    let isLoggedIn = sessionStorage.getItem("isLoggedIn");
    let userType;
    if (isLoggedIn == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      setuserDetails(urlObject);
      if (urlObject.b2b == true) {
        userType = 1;
        getCartDetails(userType, urlObject.agency.agencyId);
      } else if (urlObject.b2bUser == true) {
        userType = 2;
        getCartDetails(userType, urlObject.agencyUser.agencyUserId);
      } else if (urlObject.b2c == true) {
        userType = 3;
        getCartDetails(userType, urlObject.customer.customerId);
      }
    }
  }, [refreshKey, cartDetailList]);
  const getCartDetails = (uType, uId) => {
    let b2b = "",
      b2bu = "",
      b2c = "";
    if (uType == 1) {
      b2b = uId;
    } else if (uType == 2) {
      b2bu = uId;
    } else {
      b2c = uId;
    }
    const submitObject = {
      userType: uType,
      b2bId: b2b,
      b2bUserId: b2bu,
      b2cId: b2c,
      secretKey: requests.apiKey,
      platformId: 1,
    };
    // console.log("cartGetObj", submitObject);
    // console.log("Submit Data", `${JSON.stringify(submitObject, null, 2)}`);
    axios
      .post(requests.getCartInfoList, submitObject)
      .then((response) => {
        // console.log(response.data);
        checkTicketAvailability(response.data);
        setpostList(response.data);
        // getAllTourList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkTicketAvailability = (cList) => {
    const ticketChcked = [];
    // console.log("checkTicketAvailability", cList);
    for (let i = 0; i < cList.length; i++) {
      const tempData = {
        //ticketTypeId,attractionId,b2bId,b2bUserId
        ticketTypeId: cList[i].ticketTypeId,
        agencyId: cList[i].b2bId,
        agencyUserId: cList[i].b2bUserId,
        attractionsId: cList[i].attractionId,
      };
      // console.log("checkTicketAvailability", tempData);
      axios
        .post(requests.getAttractionTicketTypePrice, tempData)
        .then((response) => {
          // console.log(response.data);
          if (cList[i].nofAdult != 0) {
            if (response.data.errorCode == 505) {
              ticketChcked.push({
                tktId: tempData.ticketTypeId,
                attId: tempData.attractionsId,
                childtkt: 0,
              });
            }
          }
          if (cList[i].nofChild != 0) {
            if (response.data.errorCode == 504) {
              ticketChcked.push({
                tktId: tempData.ticketTypeId,
                attId: tempData.attractionsId,
                adulttkt: 0,
              });
            }
          }
        })
        .catch(() => {});

      if (i == cList.length - 1) {
        getAllTourList(cList, ticketChcked);
      } else {
      }
    }
  };
  const getAllTourList = (cList, ticketChcked) => {
    let cartList = cList,
      attlist,
      dispData = [],
      total = 0;
    axios
      .post(requests.getAttractionListForUpdate, {
        attractionId: 1,
      })
      .then((response) => {
        // console.log(response.data);
        attlist = response.data;
        for (let i = 0; i < cartList.length; i++) {
          for (let j = 0; j < attlist.length; j++) {
            //attractionId
            if (cartList[i].attractionId == attlist[j].attractionsId) {
              // console.log("att name", attlist[j].attName);
              dispData.push({
                attName: attlist[j].attName,
                attId: attlist[j].attractionsId,
                cartInfoId: cartList[i].cartInfoId,
                tktId: cartList[i].ticketTypeId,
                tktName: cartList[i].tktName,
                cartImg: attlist[j].attThumbnailImage,
                totalAmount: cartList[i].bookTotal,
              });
              total = total + cartList[i].bookTotal;
            }
          }
        }
        setbookTotal(total);
        setcartDetailList(dispData);
        // getTicketName(cartList, attlist, dispData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCartLocalStorage = () => {
    sessionStorage.removeItem("mofForBooking");
    setexitData(null);
  };
  const deleteCart = (idVal) => {
    let delId = [];
    // console.log(idVal);
    delId.push(idVal);

    // console.log(delId);
    const deleteData = {
      cartInfo: delId,
      secretKey: "uZFEucIHAbqvgT7p87qC4Ms4tjqG34su",
    };
    // console.log("deleteData", deleteData)
    axios
      .post(requests.deleteCartInfo, deleteData)
      .then((response) => {
        // console.log("response", response.data);
        // setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [tktDownload, settktDownload] = useState([]);
  const confirmtoBook = () => {
    // console.log(`${JSON.stringify(postList, null, 2)}`);

    if (exitData != "null" && exitData != undefined) {
      axios
        .post(requests.confirmTicket, exitData)
        .then((res) => {
          if (res.data.errorCode == "200") {
            encryptStorage.removeItem("mofForBooking");
            setexitData(null);
            console.log(res.data);
            let filename = res.data.pdfPath;
            const fileURL =
              // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
              "https://b2b.parmartours.com/filestorage/" + res.data.pdfPath;

            download_file_mof(fileURL, filename);
            Swal.fire({
              title: "Booking Confirmed",
              text: "Thank You For Your Booking, press OK to download your e-Ticket",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Ok",
              denyButtonText: `Don't save`,
            }).then((result) => {
              if (result.isConfirmed) {
                download_file_mof(fileURL, filename);

                //window.location.replace(fileURL)
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.errorCode,
              text: res.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let tempTktDownload = [];
    if (userDetails.b2b == true || userDetails.b2bUser == true) {
      for (let i = 0; i < postList.length; i++) {
        const submitdata = {
          bookB2bId: postList[i].b2bId,
          bookB2bUserId: postList[i].b2bUserId,
          bookB2cId: postList[i].b2cId,
          attractionsId: postList[i].attractionId,
          bookCustomerName: postList[i].passengerName,
          bookCustomerEmail: postList[i].emailId,
          bookMobileNumber: postList[i].contactNumber,
          ticketTypeId: postList[i].ticketTypeId,
          bookNofAdult: postList[i].nofAdult,
          bookNofChild: postList[i].nofChild,
          bookAdultPrice: postList[i].bookAdultPrice,
          bookChildPrice: postList[i].bookChildprice,
          bookTravellDate: postList[i].travelDate,
          bookPaymentMode: 2,
          bookTotal: postList[i].bookTotal,
          bookingAddon: [],
          secretKey: requests.apiKey,
        };

        axios
          .post(requests.postBooking, submitdata)
          .then((response) => {
            if (response.data.errorCode == 100) {
            } else if (response.data.errorCode == 505) {
            } else if (response.data.errorCode == 504) {
            } else if (response.data.errorCode == 303) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: "Please after sometime",
              });
            } else if (response.data.ticketFormat == 3) {
              window.open(
                `http://203.161.38.98:5050/parmar-tickets/${response.data.bookingReference}`,
                "_blank"
              );

              Swal.fire({
                title: "Booking Confirmed",
                text: "Thank You For Your Booking, press OK to download your e-Ticket",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Don't save`,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.open(
                    `http://203.161.38.98:5050/parmar-tickets/${response.data.bookingReference}`,
                    "_blank"
                  );

                  //window.location.replace(fileURL)
                }
              });
            } else {
              tempTktDownload.push({
                fileURL:
                  "https://www.b2b.parmartours.com/filestorage/" +
                  response.data.bookingTickPdfPath,
                filename: response.data.bookingTickPdfPath,
                cartInfoId: postList[i].cartInfoId,
              });
              if (tempTktDownload.length == postList.length) {
                // console.log(tempTktDownload);
                // console.log("if", i);
                const fileURL =
                  // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
                  "https://www.b2b.parmartours.com/filestorage/" +
                  response.data.bookingTickPdfPath;
                const filename = response.data.bookingTickPdfPath;

                Swal.fire({
                  title: "Booking Confirmed",
                  text: "Thank You For Your Booking, press OK to download your e-Ticket",
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                  denyButtonText: `Don't save`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    download_file(tempTktDownload);
                  }
                });
              } else {
              }
            }
          })
          .catch(() => {});
      }
    } else {
      Swal.fire("warning", "Now Only Avilable for agents", "warning");
    }
  };

  function download_file_mof(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
    history.goBack();
  }
  function download_file(tempTktDownload) {
    // for non-IE
    for (let i = 0; i < tempTktDownload.length; i++) {
      deleteCart(tempTktDownload[i].cartInfoId);

      let fileURL = tempTktDownload[i].fileURL;
      let fileName = tempTktDownload[i].fileName;
      if (!window.ActiveXObject) {
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
        save.download = fileName || filename;
        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          document.location = save.href;
          // window event not working here
        } else {
          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, "_blank");
        _window.document.close();
        _window.document.execCommand("SaveAs", true, fileName || fileURL);
        _window.close();
      }
    }
  }
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <TopNav />
        <DynamicBanner title="" />

        <div className="container">
          <br />
          <Row>
            <Col sm={8}>
              <section className="h-100" style={{ backgroundColor: "#eee" }}>
                <div className="container h-100 py-5">
                  <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-10">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="fw-normal mb-0 text-black">
                          Shopping Cart
                        </h3>
                        <div>
                          {/* <p className='mb-0'>
                              <span className='text-muted'>Sort by:</span>{" "}
                              <a href='#!' className='text-body'>
                                price <i className='fas fa-angle-down mt-1' />
                              </a>
                            </p> */}
                        </div>
                      </div>

                      {cartDetailList == "" && exitData == "null" ? (
                        <>
                          {" "}
                          <div className="card rounded-3 mb-4">
                            <div className="card-body p-4">
                              <div className="row d-flex justify-content-between align-items-center">
                                <img
                                  src={publicUrl + "assets/img/emptycart.png"}
                                  className="img-fluid rounded-3"
                                  alt="Empty Cart"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {exitData != "null" && exitData != undefined && (
                            <>
                              {" "}
                              <div className="card rounded-3 mb-4">
                                <div className="card-body p-4">
                                  <div className="row d-flex justify-content-between align-items-center">
                                    <div className="col-md-2 col-lg-2 col-xl-2">
                                      {/* <img
                                        src={
                                          requests.imgpath + cartData.cartImg
                                        }
                                        className="img-fluid rounded-3"
                                        alt={cartData.cartImg}
                                      /> */}
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <p className="lead fw-normal mb-2">
                                        {exitData?.parkName}
                                      </p>
                                      <p>
                                        <span className="text-muted">
                                          {exitData?.tktname}
                                        </span>
                                      </p>
                                    </div>

                                    <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                                      <h5 className="mb-0">
                                        {exitData?.totalAmount}
                                      </h5>
                                    </div>
                                    <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                      <a href="#!" className="text-danger">
                                        <i
                                          class="fa fa-trash fa-2x"
                                          aria-hidden="true"
                                          onClick={() =>
                                            deleteCartLocalStorage()
                                          }
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {cartDetailList &&
                            cartDetailList.map((cartData, index) => (
                              <>
                                {" "}
                                <div
                                  className="card rounded-3 mb-4"
                                  key={index}
                                >
                                  <div className="card-body p-4">
                                    <div className="row d-flex justify-content-between align-items-center">
                                      <div className="col-md-2 col-lg-2 col-xl-2">
                                        <img
                                          src={
                                            requests.imgpath + cartData.cartImg
                                          }
                                          className="img-fluid rounded-3"
                                          alt={cartData.cartImg}
                                        />
                                      </div>
                                      <div className="col-md-6 col-lg-6 col-xl-6">
                                        <p className="lead fw-normal mb-2">
                                          {cartData.attName}
                                        </p>
                                        <p>
                                          <span className="text-muted">
                                            {cartData.tktName}
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                                        <h5 className="mb-0">
                                          {cartData.totalAmount}
                                        </h5>
                                      </div>
                                      <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                        <a href="#!" className="text-danger">
                                          <i
                                            class="fa fa-trash fa-2x"
                                            aria-hidden="true"
                                            onClick={() =>
                                              deleteCart(cartData.cartInfoId)
                                            }
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}

                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-warning btn-block btn-lg"
                                  onClick={confirmtoBook}
                                >
                                  Book Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Col>

            <Col sm={4}>
              <section className="h-100" style={{ backgroundColor: "#eee" }}>
                <div className="container h-100 py-5">
                  <h3 className="fw-normal mb-0 text-black">Your Bill</h3>
                  <div>
                    <br />
                    {cartDetailList == "" ? (
                      <></>
                    ) : (
                      <>
                        <Table borderless>
                          {/* <thead>
                        <tr>
                          <th>Price</th>
                        </tr>
                      </thead> */}
                          <tbody>
                            <tr>
                              <td>Your Total Amount</td>
                              {exitData != "null" && exitData != undefined ? (
                                <td>{bookTotal + exitData?.totalAmount}</td>
                              ) : (
                                <td>{bookTotal}</td>
                              )}
                            </tr>
                            <tr>
                              <td>Payment Method</td>
                              <td>Credit only available Now</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </div>
      </Suspense>
    </>
  );
};

export default CartDetails;
