import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import { Row, Col } from "reactstrap";
const TopAttractions = ({ attData, login }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let isLoggedIn = sessionStorage.getItem("isLoggedIn");
  return (
    <>
      <div
        className="holiday-plan-area tp-holiday-plan-area mg-top-96"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  Top Attractions
                </h2>
                <p
                  className="wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  Leave your stresses behind and come along for the ride. The
                  world is a book and those who do not travel read only one
                  page.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {attData.map((attraction, index) => (
              <div className="col-lg-3 col-sm-6" key={index}>
                <div
                  className="single-destinations-list style-two wow animated fadeInUp single-destinations-list text-center"
                  data-wow-duration="0.4s"
                  data-wow-delay="0.1s"
                >
                  <div className="thumb">
                    <img
                      // src={publicUrl + "assets/img/destination-list/4.png"}
                      src={requests.imgpath + attraction.attThumbnailImage}
                      alt="parmartours"
                    />

                    <div className="d-list-btn-wrap">
                      <div className="d-list-btn viaje-go-top">
                        <Link
                          className="btn btn-yellow"
                          to={`tour-pack-details/${attraction.attractionsId}`}
                        >
                          Book Now <i className="fa fa-paper-plane" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <p className="location">
                      <img
                        src={publicUrl + "assets/img/icons/1.png"}
                        alt="parmartours"
                      />
                      {attraction.attCity}
                    </p>
                    <Link to={`tour-pack-details/${attraction.attractionsId}`}>
                      <p
                        className="title"
                        style={{
                          color: "#071c55",
                        }}
                      >
                        {attraction.attName}
                      </p>
                    </Link>
                    {/* <p className="content">7 Days Tour on 2 person</p> */}{" "}
                    {isLoggedIn === "yes" && (
                      <div className="tp-price-meta tp-price-meta-cl">
                        <Row>
                          <Col>
                            <p>
                              Adult Price <h2>{attraction.adultPrice}</h2>
                              <small>AED</small>
                            </p>{" "}
                          </Col>
                          <Col>
                            <p>
                              Child Price
                              <br />
                              <h2> {attraction.childPrice}</h2>{" "}
                              <small>AED</small>
                            </p>{" "}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopAttractions;
