import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import { Row, Col } from "reactstrap";
import "react-multi-carousel/lib/styles.css";
const TopDestination = ({ attData, login }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div
        className="holiday-plan-area tp-holiday-plan-area mg-top-96"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  Top Destination
                </h2>
                <p
                  className="wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  These are our top deals including the most interesting places
                  and activities in Dubai, that offers the best of Dubai city.
                  Book now with us and enjoy the adventures of Dubai
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {attData.map((attraction, index) => (
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-destinations-list style-two wow animated fadeInUp"
                  data-wow-duration="0.4s"
                  data-wow-delay="0.1s"
                >
                  <div className="thumb">
                    <img
                      // src={publicUrl + "assets/img/destination-list/4.png"}
                      src={requests.imgpath + attraction.attThumbnailImage}
                      alt="parmartours"
                    />
                  </div>
                  <div className="details">
                    <p className="location">
                      <img
                        src={publicUrl + "assets/img/icons/1.png"}
                        alt="parmartours"
                      />
                      Dubai
                    </p>
                    <Link to={`tour-pack-details/${attraction.attractionsId}`}>
                      <p
                        className="title"
                        style={{
                          color: "#071c55",
                        }}
                      >
                        {attraction.attName}
                      </p>
                    </Link>
                    {/* <p className="content">7 Days Tour on 2 person</p> */}

                    {isLoggedIn === "yes" && (
                      <div className="tp-price-meta tp-price-meta-cl">
                        <Row>
                          <Col>
                            <p>
                              Adult Price <h2>{attraction.adultPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                          <Col>
                            <p>
                              Child Price <br />
                              <h2>{attraction.childPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDestination;
