import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import requests from "../../utils/Requests";
import CommonBanner from "./common_banner";
import Footer from "./footer";
import Subscribe from "./subscribe";
import TopNav from "./top_nav";
import encryptStorage from "../../utils/Encryptstorage";
import Swal from "sweetalert2";
import { usData } from "../../utils/RdData";

const BookingConfirm = () => {
  const { tktType } = useParams();
  const xmlResponce = usData();

  const hConfig = {
    headers: {
      "access-Key": xmlResponce,
    },
  };

  const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
  let urlObject = JSON.parse(enl);
  console.log("urlObject", urlObject);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");
  const history = useHistory();
  const [serverResTitle, setserverResTitle] = useState("Please Wait");
  const [serverRes, setserverRes] = useState(
    "Please Wait your payment detail verify our side"
  );
  useEffect(() => {
    console.log("tktType", tktType);
    console.log("hConfig", hConfig);
    console.log("ref", ref);
    if (tktType === "openticket") {
      openTicketBook();
    } else if (tktType === "datedTicket") {
      bookDatedTicket();
    } else if (tktType === "comboticket") {
      bookComboTkt();
    }
  }, []);
  const openTicketBook = () => {
    const postObj = {
      bookB2bId: urlObject?.b2b == true ? urlObject.agency.agencyId : 0,
      bookB2bUserId:
        urlObject?.b2bUser == true ? urlObject.agencyUser.agencyUserId : 0,
      bookB2cId: urlObject?.b2c == true ? urlObject.customer.customerId : 0,
      paymentKey: ref,
      bookPaymentMode: 1,
      secretKey: requests.apiKey,
    };

    console.log("postObj", `${JSON.stringify(postObj, null, 2)}`);
    axios
      .post(
        requests.postBooking,
        {
          bookB2bId: urlObject?.b2b == true ? urlObject.agency.agencyId : 0,
          bookB2bUserId:
            urlObject?.b2bUser == true ? urlObject.agencyUser.agencyUserId : 0,
          bookB2cId: urlObject?.b2c == true ? urlObject.customer.customerId : 0,
          paymentKey: ref,
          bookPaymentMode: 1,
          secretKey: requests.apiKey,
        },
        hConfig
      )
      .then((response) => {
        console.log("final res", response.data);
        if (response.data.errorCode == 291) {
          Swal.fire({
            title: "Sorry !",
            text: response.data.errorMessage,
            icon: "error",
          });
          setserverResTitle("Payment Failure");
          setserverRes(response.data.errorMessage);
        }
        if (response.data.errorCode == 563) {
          Swal.fire({
            title: "Sorry !",
            text: response.data.errorMessage,
            icon: "error",
          });
          setserverResTitle("Payment Failure");
          setserverRes(response.data.errorMessage);
        } else if (response.data.errorCode == 200) {
          console.log("responce", response);
          setserverResTitle("Dear Customer , Thank You for booking with us,");
          setserverRes(
            "your booking has been success , and your ticket has been send to your email"
          );
          const fileURL =
            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
            "https://b2b.parmartours.com/filestorage/" +
            response.data.bookingTickPdfPath;
          const filename = "e-Ticket.pdf";
          //download_file(fileURL, filename);

          Swal.fire({
            title: "Booking Confirmed",
            text: "Thank You For Your Booking, press OK to download your e-Ticket",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              download_file(fileURL, filename);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Swal.fire({
  //   title: "Booking Confirmed",
  //   text: "Thank You For Your Booking, press OK to download your e-Ticket",
  //   showDenyButton: false,
  //   showCancelButton: false,
  //   confirmButtonText: "Ok",
  //   denyButtonText: `Don't save`,
  // }).then((result) => {
  //   /* Read more about isConfirmed, isDenied below */
  //   if (result.isConfirmed) {
  //     download_file(fileURL, filename);
  //   }
  // });

  // const tktdownload =(fileURL, filename)=>{

  // }

  const bookComboTkt = () => {
    axios
      .post(
        requests.setComboBooking,
        {
          bookB2bId: urlObject?.b2b == true ? urlObject.agency.agencyId : 0,
          bookB2bUserId:
            urlObject?.b2bUser == true ? urlObject.agencyUser.agencyUserId : 0,
          bookB2cId: urlObject?.b2c == true ? urlObject.customer.customerId : 0,
          paymentKey: ref,
          bookPaymentMode: 1,
          secretKey: requests.apiKey,
        },
        hConfig
      )
      .then((response) => {
        // console.log("res", response.data);
        if (response.data.errorCode == 505) {
          //no adult tkt
          Swal.fire({
            title: "Sorry !",
            text: "Ticket Not Available",
            icon: "error",
          });
        } else if (response.data.errorCode == 504) {
          // no child tkt
          Swal.fire({
            title: "Sorry !",
            text: "Ticket Not Available",
            icon: "error",
          });
        } else if (response.data.errorCode == 100) {
          Swal.fire({
            title: "Sorry !",
            text: "Insuffecient Balance",
            icon: "error",
          });
        } else if (response.data.errorCode == 303) {
          Swal.fire({
            title: "Sorry !",
            text: "Some Technical Problem",
            icon: "error",
          });
        } else if (response.data.errorCode == 291) {
          Swal.fire({
            title: "Sorry !",
            text: response.data.errorMessage,
            icon: "error",
          });
          setserverResTitle("Payment Failure");
          setserverRes(response.data.errorMessage);
        } else {
          setserverResTitle("Dear Customer , Thank You for booking with us,");
          setserverRes(
            "your booking has been success , and your ticket has been send to your email"
          );
          const fileURL =
            " https://b2b.parmartours.com/filestorage/" +
            response.data.bookingTickPdfPath;
          const filename = response.data.bookingTickPdfPath;

          Swal.fire({
            title: "Booking Confirmed",
            text: "Thank You For Your Booking, press OK to download your e-Ticket",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`,
          }).then((result) => {
            if (result.isConfirmed) {
              download_file(fileURL, filename);
            }
          });
        }
      })
      .catch((error) => {});
  };

  const bookDatedTicket = () => {
    axios
      .post(
        requests.confirmBurjTicket,
        {
          bookB2bId: urlObject?.b2b == true ? urlObject.agency.agencyId : 0,
          bookB2bUserId:
            urlObject?.b2bUser == true ? urlObject.agencyUser.agencyUserId : 0,
          bookB2cId: urlObject?.b2c == true ? urlObject.customer.customerId : 0,
          paymentKey: ref,
          bookPaymentMode: 1,
          secretKey: requests.apiKey,
        },
        hConfig
      )
      .then((res) => {
        if (res.data.errorCode == 291) {
          Swal.fire({
            title: "Sorry !",
            text: res.data.errorMessage,
            icon: "error",
          });
        } else {
          const fileURL =
            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
            "https://b2b.parmartours.com/filestorage/" +
            res.data.ticketFilePath;
          const filename = "e-Ticket.pdf";
          //download_file(fileURL, filename);

          Swal.fire({
            title: "Booking Confirmed",
            text: "Thank You For Your Booking, press OK to download your e-Ticket",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              download_file(fileURL, filename);
            }
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* Helper function */
  function download_file(fileURL, fileName) {
    // for non-IE

    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    encryptStorage.removeItem("suckapi");
    encryptStorage.removeItem("firstSubmitForBooking");

    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }

    history.push("/attraction");
  }
  return (
    <div>
      <TopNav />
      <CommonBanner imgUrl="thankyou.jpg" />
      <div className="container">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="d-flex justify-content-center">
            <h3 className="title">{serverResTitle}</h3>
          </div>
          <div className="d-flex justify-content-center">
            <p>{serverRes}</p>
          </div>
        </div>
      </div>

      <Subscribe />
      <Footer />
    </div>
  );
};

export default BookingConfirm;
